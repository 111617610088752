<template>
  <div class="salad" :class="[background]">
    {{ block }}
  </div>
</template>

<script>
import { background } from '@/mixins';

export default {
  name: 'BlockLogos',
  props: {
    block: [Array, Object]
  },
  mixins: [background]
};
</script>

<style lang="postcss" scoped>
.salad {
  @apply flex items-center justify-center;
  @apply p-16;
  @screen sm {
    @apply min-h-screen;
    @apply py-32;
  }
}
</style>
