<template>
  <header
    ref="header"
    class="core-header"
    :class="[getIsMenuOpen ? 'open' : 'close']"
  >
    <div ref="headerbar" class="core-header__bar">
      <div class="core-header__bar__container">
        <button class="core-header__bar__burger-menu" @click="toggleMenu">
          <IconBurgerMenu />
        </button>
        <div class="core-header__bar__logo">
          <LogoEmblem />
        </div>
      </div>
    </div>
    <div class="core-header__content" :style="openHeight">
      <div class="core-header__content__wrapper">
        <div class="core-header__content__container">
          <nav
            ref="primaryMenu"
            class="primaryMenu"
            :style="[navStyles, navTransform]"
          >
            <div class="logo">
              <router-link class="h-full w-full" to="/">
                <LogoNav />
              </router-link>
            </div>
            <ul>
              <div class="left">
                <li style="animation-delay: 0.2s">
                  <router-link to="/">Home</router-link>
                </li>
                <li
                  v-if="getIsMobile"
                  style="animation-delay: 0.3s
                  "
                >
                  <router-link to="/about-us">
                    About us
                  </router-link>
                </li>
                <li
                  v-else
                  @click="
                    toggleSubMenu();
                    aboutus = !aboutus;
                  "
                >
                  <router-link to="/about-us">
                    About us
                  </router-link>
                </li>
                <li
                  style="animation-delay: 0.4s"
                  @click="
                    toggleSubMenu();
                    grantmaking = !grantmaking;
                  "
                >
                  <span v-if="getIsMobile">Grantmaking</span>
                  <router-link to="/grantmaking" v-else>
                    Grantmaking
                  </router-link>
                  <div class="icon">
                    <IconArrowMenu direction="next" />
                  </div>
                </li>
                <li style="animation-delay: 0.5s">
                  <router-link to="/success-stories"
                    >Success Stories</router-link
                  >
                </li>
              </div>
              <div class="right">
                <li style="animation-delay: 0.6s">
                  <router-link to="/news">News & Insights</router-link>
                </li>
                <li style="animation-delay: 0.7s">
                  <router-link to="/reports-and-resources"
                    >Reports & Resources</router-link
                  >
                </li>
                <li style="animation-delay: 0.8s">
                  <router-link to="/contact">Contact</router-link>
                </li>
              </div>
            </ul>
          </nav>
          <nav
            ref="subMenu"
            class="subMenu"
            :style="[navStyles, navTransform]"
            :class="
              this.$route.path.split('/')[1] === 'about-us' ||
              this.$route.path.split('/')[1] === 'grantmaking' ||
              this.$route.path.split('/')[1] === 'grants' ||
              this.$route.path.split('/')[1] === 'small-grants' ||
              this.$route.path.split('/')[1] === 'fivefields' ||
              this.$route.path.split('/')[1] === 'event-space' ||
              this.$route.path.split('/')[1] === 'youth-voice'
                ? 'is-visible'
                : null
            "
          >
            <div
              class="subMenu__back"
              @click="
                toggleSubMenu();
                aboutus = false;
                grantmaking = false;
              "
            >
              <IconArrowMenu direction="back" />
              <span>Back to menu</span>
            </div>
            <ul
              :class="[
                'subMenu__list',
                this.$route.path.split('/')[1] === 'about-us'
                  ? 'is-visible'
                  : null,
                this.aboutus === true ? 'is-active' : null
              ]"
            >
              <li>
                <a href="/about-us">About us</a>
              </li>
              <li>
                <button @click="scrollToSection('where-we-fund')">
                  Where we fund
                </button>
              </li>
              <li>
                <button @click="scrollToSection('what-we-fund')">
                  What we fund
                </button>
              </li>
              <li>
                <button @click="scrollToSection('trustees')">
                  Our Trustees
                </button>
              </li>
              <li>
                <button @click="scrollToSection('meet-the-team')">
                  Our Team
                </button>
              </li>
              <li>
                <button @click="scrollToSection('our-history')">
                  Our History
                </button>
              </li>
            </ul>
            <ul
              :class="[
                'subMenu__list',
                this.$route.path.split('/')[1] === 'grantmaking' ||
                this.$route.path.split('/')[1] === 'grants' ||
                this.$route.path.split('/')[1] === 'small-grants' ||
                this.$route.path.split('/')[1] === 'fivefields' ||
                this.$route.path.split('/')[1] === 'event-space' ||
                this.$route.path.split('/')[1] === 'youth-voice'
                  ? 'is-visible'
                  : null,
                this.grantmaking === true ? 'is-active' : null
              ]"
            >
              <li>
                <router-link to="/grantmaking">Grantmaking</router-link>
              </li>
              <li>
                <router-link to="/grants">Grants</router-link>
              </li>
              <li>
                <router-link to="/small-grants">Small Grants</router-link>
              </li>
              <li>
                <router-link to="/fivefields">Fivefields</router-link>
              </li>
              <li>
                <router-link to="/event-space"
                  >Meeting & Event Space</router-link
                >
              </li>
              <!-- <li>
                <router-link to="/youth-voice">Youth Voice</router-link>
              </li> -->
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

import _ from 'lodash';

import IconBurgerMenu from '@/components/Icon/IconBurgerMenu';
import IconArrowMenu from '@/components/Icon/IconArrowMenu';
import LogoEmblem from '@/components/Logo/LogoEmblem';
import LogoNav from '@/components/Logo/LogoNav';

export default {
  name: 'CoreHeader',
  components: {
    IconBurgerMenu,
    IconArrowMenu,
    LogoEmblem,
    LogoNav
  },
  data: function() {
    return {
      navStyles: {
        width: this.getIsMobile ? `${window.innerWidth * 0.9}px` : '100%'
      },
      aboutus: false,
      grantmaking: false
    };
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
      'getIsMenuOpen',
      'getIsSubMenuOpen',
      'getHeightHeader'
    ]),
    openHeight() {
      if (this.getIsMobile) {
        return this.getIsMenuOpen
          ? `height: ${window.innerHeight - this.getHeightHeader}px`
          : '';
      }
      return false;
    },
    navTransform() {
      return {
        transform:
          this.getIsSubMenuOpen && this.getIsMobile
            ? `translateX(-${this.navStyles.width})`
            : 'translateX(0px)'
      };
    }
  },
  methods: {
    toggleMenu() {
      this.$store.commit('setIsMenuOpen', !this.getIsMenuOpen);
    },
    toggleSubMenu() {
      this.getIsMobile
        ? this.$store.commit('setIsSubMenuOpen', !this.getIsSubMenuOpen)
        : null;
    },
    setHeightHeader() {
      this.$store.commit(
        'setHeightHeader',
        this.getIsMobile
          ? this.$refs.headerbar.offsetHeight
          : this.$refs.primaryMenu.offsetHeight
      );
    },
    init() {
      this.navStyles = {
        width: this.getIsMobile ? `${window.innerWidth * 0.9}px` : '100%'
      };
      this.setHeightHeader();
    },
    scrollToSection(sectionId) {
      document.getElementById(sectionId).scrollIntoView();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
      window.addEventListener('resize', _.debounce(this.init, 400));
    });
  }
};
</script>

<style lang="postcss" scoped>
@keyframes FadeIn {
  0% {
    @apply opacity-0;
    transform: translateX(-25px);
  }
  100% {
    @apply opacity-100;
    transform: translateX(0px);
  }
}

.core-header {
  @apply fixed top-0;
  @apply w-full;
  z-index: 100;
  @apply bg-brand-white;
  @apply border-b border-brand-black;
  @screen lg {
    @apply border-b-0;
    @apply shadow-standard;
  }
  &__bar {
    transition-duration: 250ms;
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    &__container {
      @apply py-4;
      @apply max-w-max mx-auto;
      @apply flex items-center;
    }
    &__burger-menu {
      @apply p-4;
      @apply -ml-4;
    }
    &__logo {
      @apply w-full;
      @apply flex justify-center items-center;
      @apply absolute left-0;
      @apply pointer-events-none;
    }
    @screen lg {
      @apply hidden;
    }
  }
  &.close {
    .core-header__content {
      @apply h-0;
      @screen lg {
        @apply h-auto;
      }
    }
  }
  &.open {
    @apply border-brand-lightGrey;
    @apply h-full;
    .core-header__bar {
      @apply shadow-standard;
      @apply relative;
    }
    nav.primaryMenu {
      li {
        animation: FadeIn 250ms linear;
        animation-fill-mode: both;
        animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
      }
    }
  }
  &__content {
    @apply overflow-hidden;
    transition-property: height;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    @apply bg-brand-lightGrey;
    @screen lg {
      @apply bg-brand-white;
    }
    &__wrapper {
      @apply py-4;
      @apply overflow-x-hidden;
      @apply max-w-max mx-auto;
      @screen lg {
        @apply max-w-none;
        @apply py-0;
        @apply border-b border-black;
      }
    }
    &__container {
      @apply flex;
      width: 2000px;
      @screen lg {
        @apply w-full;
        @apply flex-wrap;
        @apply overflow-hidden;
      }
    }
    nav {
      transition-property: transform;
      transition-duration: 250ms;
      transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
      ul {
        li {
          @apply py-8;
          @apply flex justify-between items-center;
          @apply border-b border-brand-border;
          @screen lg {
            @apply inline-block;
            @apply py-8;
            @apply border-b-0;
          }
          span,
          a,
          button {
            @apply font-ginto-regular text-brand-28 leading-none;
            @apply cursor-pointer;
            @apply relative;
            @screen lg {
              @apply text-brand-16;
              @apply border-b-0;
              @apply inline-block;
              @apply border-b-0;
              @apply py-2;
            }
            outline: none;
            &:after {
              transition-duration: 500ms;
              transition-property: all;
              transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
              @screen lg {
                content: '';
                @apply absolute bottom-0 left-0;
                @apply w-full;
                height: 1px;
                transform: scale(0.1);
                @apply bg-brand-black;
              }
            }
            &:hover {
              &:after {
                @screen lg {
                  transform: scale(1);
                }
              }
            }
            &.active,
            &.router-link-exact-active {
              &:after {
                @screen lg {
                  content: '';
                  @apply absolute bottom-0 left-0;
                  @apply w-full;
                  height: 1px;
                  transform: scale(1);
                  @apply bg-brand-black;
                }
              }
            }
          }
          &:last-of-type {
            @screen lg {
              @apply mr-0;
            }
          }
          .icon {
            @screen lg {
              @apply hidden;
            }
          }
        }
      }
      &.primaryMenu {
        @screen lg {
          @apply relative;
          @apply w-full;
          @apply max-w-max mx-auto;
        }
        .logo {
          @apply hidden;
          @screen lg {
            @apply absolute;
            @apply flex justify-center items-center;
            @apply w-full h-full;
          }
        }
        ul {
          @screen lg {
            @apply flex justify-between;
            @apply py-1;
          }
          li {
            @screen lg {
              @apply relative;
              @apply px-5;
            }
            &:not(:last-child):after {
              @screen lg {
                content: '';
                @apply absolute top-0 right-0;
                width: 1px;
                height: 48px;
                @apply my-4;
                @apply bg-brand-border;
              }
            }
            span {
              @screen lg {
                @apply relative;
                @apply mx-4;
              }
            }
          }
          .left {
            @screen lg {
              @apply w-1/2;
            }
            li {
              &:first-of-type {
                @screen lg {
                  @apply pl-0;
                }
                span {
                  @screen lg {
                    @apply ml-0;
                  }
                }
              }
            }
          }
          .right {
            @screen lg {
              @apply w-1/2;
              @apply flex justify-end;
            }
            li {
              &:last-of-type {
                @screen lg {
                  @apply pr-0;
                }
                span {
                  @screen lg {
                    @apply mr-0;
                  }
                }
                &:after {
                  @screen lg {
                    @apply bg-transparent;
                  }
                }
              }
            }
          }
        }
      }
      &.subMenu {
        display: none;
        @media screen and (max-width: 1023px) {
          display: block;
        }
        &.is-visible {
          @screen lg {
            display: block;
          }
        }
        @screen lg {
          @apply bg-white;
          @apply border-t border-brand-black;
          @apply w-full;
        }
        ul.subMenu__list {
          display: none;
          &.is-active {
            @media screen and (max-width: 1023px) {
              display: block;
              z-index: 2;
            }
          }
          &.is-visible {
            @screen lg {
              @apply max-w-max mx-auto;
              display: flex;
              gap: 30px;
            }
          }
          li {
            @screen lg {
            }
            &:first-of-type {
              @screen lg {
                @apply hidden;
              }
            }
            &:nth-child(2) {
              @screen lg {
                @apply pl-0;
              }
            }
            &:nth-child(n + 2) {
              span {
                @apply ml-8;
                @screen lg {
                  @apply ml-0;
                }
              }
            }
            &:last-of-type {
              @screen lg {
                @apply pr-0;
              }
            }
          }
        }
      }
      .subMenu__back {
        @apply w-full;
        @apply flex items-center;
        @apply border-b border-brand-border;
        @apply py-8;
        span {
          @apply ml-4;
          @apply inline-block;
          font-size: calc(18px + (18 - 18) * (100vw - 640px) / (1920 - 640));
        }
        @screen lg {
          @apply hidden;
        }
      }
    }
  }
}
</style>
